import consumer from "./consumer"

consumer.subscriptions.create("ImportCustomerChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (data.kind == 'start') {
      $('#import_customer_end').addClass("d-none")
      $('#import_customer_error').addClass("d-none")
    } else if (data.kind == 'finish') {
      $('.import_customer_start').addClass("d-none")
      $('#import_customer_end').removeClass("d-none")
      $('#import_customer_error').addClass("d-none")
    } else if (data.kind == 'rm_btn') {
      $('#errors_btn').addClass("d-none")
    } else if (data.kind == 'file_error') {
      $('.import_customer_start').addClass("d-none")
      $('#import_customer_end').addClass("d-none")
      $('#import_customer_error').addClass("d-none")
      $('#import_customer_file_error').removeClass("d-none")
    } else {
      $('.import_customer_start').addClass("d-none")
      $('#import_customer_end').addClass("d-none")
      $('#import_customer_error').removeClass("d-none")
      // $('#import_customer_error').text("Errore durante l'importazione! Compilare gli spazi vuoti nel file di errori")
    }
  }
});
