$(document).ready(function() {
  assignProvinces();
  getPrinterHeads();
  checkAll();
  checkCountries();
  uncheckRegion();
  checkErrors();
  collapseIconChange();
  colspans();
  dragDrop();
  fakeDateField();
  initilizeUppy();
  selectpicker();
  slideDropdown();
  spinner();
  toggleFields();
  toggles();
  tooltip();
  updateSelect();
});

$(document).ajaxComplete(function() {
  getCustomerMachinesNoWarranty();
  getPrinterHeads();
  initilizeUppy();
  selectpicker();
  toggleFields();
  updateSelect();
});

$(window).on('scroll', function(event) {
  var scrollValue = $(window).scrollTop();
  if (scrollValue >= 98) {
    $('#top_assign_customer_machine').addClass('d-none');
    $('#bigger-btn-div').removeClass('d-none')
    $('#scroll_assign_customer_machine').removeClass('d-none')
  } else if (scrollValue <= 98) {
    $('#top_assign_customer_machine').removeClass('d-none');
    $('#bigger-btn-div').addClass('d-none')
    $('#scroll_assign_customer_machine').addClass('d-none')
  }
});

$(window).click(function(event) {
  if ($(".modal.show").length == 0) {
    $('.dropdown-toggle.header').not(".bs-placeholder").each(function(i, el) {
      $(this).closest('.dropdown-menu.show').each(function(i, el) {
        $(el).toggle("show");
      })
      $(this).next('.dropdown-menu').slideUp(250);
    })
  }
});

window.slideDropdown = function() {
  $('.dropdown-toggle.header').not(".bs-placeholder").on('click', function(el) {
    $('.dropdown-menu.show').each(function(i, el) {
      $(el).toggle("show");
    })
    if ($(this).parent().hasClass("show")) {
      $(this).next('.dropdown-menu').slideUp(250);
    } else {
      $(this).next('.dropdown-menu').slideDown(250);
    }
  });
}

window.getCustomerMachinesNoWarranty = function() {
  $("select[id='contract_customer_id']").each(function(i, el) {
    $(el).change( function(el){
      var customer_id = this.value;
      var customer_machine_id = $("#contract_customer_machine_id");
      if (customer_id) {
        url = "/contracts/get_enum?customer_id=" + customer_id;
        $.getJSON(url, function(json){
          customer_machine_id.empty();
          $.each(json, function(i, obj){
            customer_machine_id.append($('<option>').text(obj.text).attr('value', obj.value));
          });
        });
      }
    });
  });
}

window.getPrinterHeads = function() {
  $("select[id='customer_machine_machine_id']").each(function(i, el) {
    $(el).change( function(el){
      var machine_id = this.value;
      var printer_heads = $("#customer_machine_printer_head_id");
      if (machine_id) {
        url = "/machines/" + machine_id + "/get_enum";
        $.getJSON(url, function(json){
          printer_heads.empty();
          $.each(json, function(i, obj){
            printer_heads.append($('<option>').text(obj.text).attr('value', obj.value));
          });
        });
      }
    });
  });
  $("select[id='inspection_customer_id']").each(function(i, el) {
    $(el).change( function(el){
      var customer_id = this.value;
      var customer_machines = $("#inspection_customer_machine_id");
      var contracts = $("#inspection_contract_id");
      var warranty = $("#inspection_warranty");
      var is_contract = $("#inspection_is_contract");
      var paid_exit = $("#paid_exit");
      if (customer_id) {
        url = "/customers/" + customer_id + "/get_data_enum";
        $.getJSON(url, function(json){
          customer_machines.empty();
          $.each(json['customer_machines'], function(i, obj){
            customer_machines.append($('<option>').text(obj.text).attr('value', obj.value).attr('warranty', obj.warranty));
          });
          contracts.empty();
          $.each(json['contracts'], function(i, obj){
            contracts.append($('<option>').text(obj.text).attr('value', obj.value));
          });
          warranty.val(String(json['warranty']))
          is_contract.val(String(json['contract']))
          paid_exit.val(String(json['paid_exit']))
        });
      }
    });
  });
  $("select[id='inspection_customer_machine_id']").each(function(i, el) {
    $(el).change( function(el){
      var customer_machine_id = this.value;
      var warranty = $("#inspection_warranty");
      var is_contract = $("#inspection_is_contract");
      var paid_exit = $("#paid_exit");
      if (customer_machine_id) {
        url = "/customer_machines/" + customer_machine_id + "/get_warranty_enum";
        $.getJSON(url, function(json){
          warranty.val(String(json['warranty']))
          is_contract.val(String(json['contract']))
          paid_exit.val(String(json['paid_exit']))
        });
      }
    });
  });
}

window.checkErrors = function() {
  $(".check_errors").each(function(i, el) {
    $(el).hover(function() {
      var children = $(el).children(".errors_tooltip:first");
      children.toggleClass("d-none");
    });
  });
}

window.collapseIconChange = function() {
  $("[data-toggle='collapse']").each(function(i,el) {
    $(el).click(function() {
      target = $($(el).data('target'));
      children = $(el).find("svg");
      if ($(el).data('parent') && !target.hasClass('show')) {
        $('.fa-caret-square-down').each(function(j, obj) {
          $(obj).removeClass('fa-caret-square-up');
          $(obj).addClass('fa-caret-square-down');
        });
      }
      if (children.hasClass('fa-caret-square-up')) {
        children.removeClass('fa-caret-square-up').addClass('fa-caret-square-down');
      } else {
        children.removeClass('fa-caret-square-down').addClass('fa-caret-square-up');
      }
    });
  });
}

window.colspans = function() {
  $('td.colspan').each(function(i, el) {
    var count = 0
    $(el).closest('table').find('tr th').each(function(i, el) {
      if ($(el).attr('colspan')) {
        count += parseInt($(el).attr('colspan'));
      } else {
        count += 1;
      }
    });
    $(el).attr('colspan', count);
  });
}

window.checkAll = function() {
  $("#check_all").click(function(){
    $("input[type=checkbox]").prop('checked', $(this).prop('checked'));
  });
}

window.checkCountries = function() {
  $("input[name=check_all_region]").click(function(){
    var checked =  $(this).is(":checked")
    $("input[class=check_province_" + $(this).attr("class").toString().split("_").pop() + "]").each(function(i, el) {
      $(el).prop('checked', checked);
    })
  });
}

window.uncheckRegion = function() {
  $("input[name=check_province]").click(function(){
    var res = []
    var index = $(this).attr("class").toString().split("_").pop();
    $("input[class=check_province_" + index + "]").each(function(i, el) {
      res.push($(el).prop('checked'))
    })
    var unique = res.filter(function(itm, i, res) {
      return i == res.indexOf(itm);
    });
    var region = $("input[class=check_region_" + index + "]");
    if (unique.length == 1 && region.prop("checked") == true) {
      region.prop("checked", false)
    }
  });
}

window.assignProvinces = function() {
  $("#assign_provinces").click(function(){
    var url = "/users/" + $(this).data("user-id") + "/assign_provinces";
    var provinces = $('input[name=check_province]:checkbox:checked').map(function() {
      return this.value;
    }).get();
    if (provinces.length > 0) {
      $.ajax({
        type: 'GET',
        url: url,
        data: { provinces: provinces },
        dataType: 'script'
      });
      return false;
    } else {
      alert('Selezionare almeno una provincia.')
      location.reload();
    }
  });
}

window.fakeDateField = function() {
  $(".fake_date_field").each(function(i,el) {
    if ($(el).val()) {
      this.type = 'date';
    }
    $(el).on('focus', function () {
      this.type = 'date';
      this.click();
    });
    $(el).on('focusout', function () {
      if (this.value == '') {
        this.type = 'text';
      }
    });
  });
}

window.selectpicker = function() {
  $('.selectpicker').selectpicker();
}

window.toggleFields = function() {
  $("select[data-behaviour='toggle_fields']").each(function(i, el) {
    toggleFieldsVisibility(el);
    $(el).change(function() {
      toggleFieldsVisibility(el);
    });
  });
}

window.toggleFieldsVisibility = function() {
  $("[data-dependency]").each(function(i,el) {
    target = $(this).data("dependency");
    if (target == "customer_machine_machine_id") {
      values = $(this).data('dependencyvalue').toString().split(',');
    } else {
      values = $(this).data('dependencyvalue').toString().split(',');
    }
    condition = $(this).data('dependencycondition');
    target_value = $("#" + target).val();
    switch(condition) {
      case 'Not-equals':
        if ($.inArray( target_value, values ) == -1 && target_value != '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
        break;
      case 'Equals':
        if ($.inArray( target_value, values ) != -1 && target_value != '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
        break;
      case 'Contains':
        if ( String(target_value).indexOf(String(values)) >= 0 && target_value != '' ) {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
      case 'Does not contain':
        if ( String(target_value).indexOf(String(values)) == -1 && target_value != '' ) {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
      case 'Starts with':
        if (String(target_value).match("^" + String(values)) && target_value != '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
      case 'Does not start with':
        if ( String(target_value).match("^" + String(values)) == null && target_value != '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
      case 'IsNil':
        if (target_value == '' && values == '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
        break;
      }
  });
}

window.initilizeUppy = function() {
  $(".upload_file").on("dragover", function(e) {
    $(this).click();
  });
  if($("#drag-drop-area").length > 0) {
    const Uppy = require('@uppy/core')
    const XHRUpload = require('@uppy/xhr-upload')
    const Dashboard = require('@uppy/dashboard')
    require('@uppy/core/dist/style.css')
    require('@uppy/dashboard/dist/style.css')
    const Italian = require('@uppy/locales/lib/it_IT')
    if ($("#drag-drop-area[data-uppy-max-files]").length) {
      uppy_max_files = $('#drag-drop-area').data("uppy-max-files");
    } else {
      uppy_max_files = 1
    }
    const uppy = Uppy({
      autoProceed: false,
      locale: Italian,
      restrictions: {
        // maxFileSize: 300000,
        maxNumberOfFiles: uppy_max_files,
        minNumberOfFiles: 1
        // allowedFileTypes: ['image/*', 'video/*']
      }
    })
    uppy.use(Dashboard, {
        target: '#drag-drop-area',
        inline: true,
        height: 300
    });
    uppy.use(XHRUpload, {
      endpoint: $('#drag-drop-area').data('url'),
      timeout: 300 * 1000,
      bundle: true
    })
    uppy.on('complete', (result) => { location.reload(); })
    $('.uppy-Dashboard-poweredBy').hide();
  }
}

window.toggles = function() {
  $("[data-behaviour='toggle']").each(function(i, el) {
    var div = $(el);
    div.children('a').on('click', function () {
      var url = $(this).data('url');
      $.ajax({
        type: 'PATCH',
        url: url,
        beforeSend: function() {
          $(this).removeClass('btn-success').removeClass('btn-danger').addClass('btn-warning');
        },
        success: function(data) {
          div.replaceWith(data);
        },
        dataType: 'html'
      });
    });
  });
}

window.tooltip = function() {
  $('[data-toggle="tooltip"]').tooltip();
}

window.spinner = function() {
  $(window).on("load",function(){
    $("#spinner").hide();
  });
  $('[data-spinner="true"]').on('click',function(){
    $("#spinner").show();
  });
}

window.updateSelect = function() {
  $("[data-update='address']").change( function(el){
    value = this.value;
    if(value != 'Seleziona...') {
      name = $(this).attr('id');
      target = $("#" + name);
      datatarget1 = $(this).data('target1');
      target1 = $("#" + datatarget1);
      datatarget2 = $(this).data('target2');
      target2 = $("#" + datatarget2);
      datatarget3 = $(this).data('target3');
      target3 = $("#" + datatarget3);
      url = "/cities/get_enum?" + name + "=" + value;
      $.getJSON(url, function(json){
        target1.empty();
        target1.append($('<option>').text("Seleziona...").attr('value', ''));
        $.each(json[datatarget1], function(i, obj){
          target1.append($('<option>').text(obj.text).attr('value', obj.text));
        });
        if(json[datatarget1].length == 1) {
          $("#" + datatarget1 + " option:last").attr("selected",true);
        }
        target2.empty();
        target2.append($('<option>').text("Seleziona...").attr('value', ''));
        $.each(json[datatarget2], function(i, obj){
          target2.append($('<option>').text(obj.text).attr('value', obj.text));
        });
        if(json[datatarget2].length == 1) {
          $("#" + datatarget2 + " option:last").attr("selected",true);
        }
        target3.empty();
        target3.append($('<option>').text("Seleziona...").attr('value', ''));
        $.each(json[datatarget3], function(i, obj){
          target3.append($('<option>').text(obj.text).attr('value', obj.text));
        });
        if(json[datatarget3].length == 1) {
          $("#" + datatarget3 + " option:last").attr("selected",true);
        }
      });
    }
  });
}

// draggable
window.handleDragStart = function(e) {
  $(this).attr("move", "true")
  e.dataTransfer.effectAllowed = 'drag';
  e.dataTransfer.setData('id', $(this).attr('id'));
  $(this).addClass('drag_start');

  $(".dragg").each(function(i,el) {
    $(el).addClass('undraggable');
  });

  $(".dropzone").each(function(i,el) {
    $(el).addClass('drag_selection');
  });
}

window.handleDragOver = function(e) {
  e.preventDefault();
}

window.handleDrop = function(e) {
  e.preventDefault();
  e.stopPropagation(); // stops the browser from redirecting.
  var id = ''
  $(".dragg").each(function(i,el){
    if ($(el).attr("move") == "true") {
      id = $(el).attr('id')
    }
  });
  var slot_id = ""
  if (id == 'cmyk') {
    var c_id = getId($(this).attr('id'), 0);
    var m_id = getId($(this).attr('id'), 1);
    var y_id = getId($(this).attr('id'), 2);
    var k_id = getId($(this).attr('id'), 3);
    $("#" + c_id).removeClass("dropzone");
    $("#" + m_id).removeClass("dropzone");
    $("#" + y_id).removeClass("dropzone");
    $("#" + k_id).removeClass("dropzone");
    slot_id = c_id;
  } else if (id == 'light') {
    var lc_id = getId($(this).attr('id'), 0);
    var lm_id = getId($(this).attr('id'), 1);
    $("#" + lc_id).removeClass("dropzone");
    $("#" + lm_id).removeClass("dropzone");
    slot_id = lc_id;
  } else if (["varnish", "white", "primer", "spot"].includes(id)) {
    $(this).removeClass("dropzone");
    slot_id = $(this).attr('id');
  }
  var nozzle_id = e.dataTransfer.getData('id');
  var url = $(this).attr("printer_head_id") + '/update_nozzles_configuration';
  $.ajax({
    type: 'PATCH',
    url: url,
    data: { slot_id: slot_id, nozzle_id: nozzle_id }
  });
  return false;
}

window.handleDragEnd = function(e) {
  // Qua ho finito l'azione dell'oggetto che sto spostando
  e.preventDefault();
  $(this).removeClass('drag_start');
  $(this).attr("move", "false")
  $(".dragg").each(function(i,el) {
    $(el).removeClass('undraggable');
  });
  $(".dropzone").each(function(i,el) {
    $(el).removeClass('drag_selection').removeClass('box_over');
  });
}

window.handleDragEnter = function(e) {
  var id = ''
  $(".dragg").each(function(i,el){
    if ($(el).attr("move") == "true") {
      id = $(el).attr('id')
    }
  });
  var ret = [];
  if (id == 'cmyk') {
    for(var i = 0; i < 4; i++) {
      var id = getId($(this).attr('id'), i);
      if ($("#" + id).hasClass('dropzone')){
        ret.push(true)
      } else {
        ret.push(false)
      }
    }
    var unique = ret.filter(function(itm, i, a) {
      return i == ret.indexOf(itm);
    });
    if (!(unique.includes(false))) {
      var c_id = getId($(this).attr('id'), 0);
      var m_id = getId($(this).attr('id'), 1);
      var y_id = getId($(this).attr('id'), 2);
      var k_id = getId($(this).attr('id'), 3);
      $("#" + c_id).css('background-color', "#00FFFF");
      $("#" + m_id).css('background-color', "#FF00FF");
      $("#" + y_id).css('background-color', "#FFFF00");
      $("#" + k_id).css('background-color', "#000000");
    }
  } else if (id == 'light') {
    for(var i = 0; i < 2; i++) {
      var id = getId($(this).attr('id'), i);
      if ($("#" + id).hasClass('dropzone')){
        ret.push(true)
      } else {
        ret.push(false)
      }
    }
    var unique = ret.filter(function(itm, i, a) {
      return i == ret.indexOf(itm);
    });
    if (!(unique.includes(false))) {
      var lc_id = getId($(this).attr('id'), 0);
      var lm_id = getId($(this).attr('id'), 1);
      $("#" + lc_id).css('background-color', "#E0FFFF");
      $("#" + lm_id).css('background-color', "#FF80FF");
    }
  } else if (id == 'varnish') {
    if ($(this).hasClass('dropzone')){
      $(this).css('background-color', "#B6B6B6");
    }
  } else if (id == 'white') {
    if ($(this).hasClass('dropzone')){
      $(this).css('background-color', "#FFFFFF");
    }
  } else if (id == 'primer') {
    if ($(this).hasClass('dropzone')){
      $(this).css('background-color', "#B1BA89");
    }
  } else if (id == 'spot') {
    if ($(this).hasClass('dropzone')){
      $(this).css('background-color', "#0ABAB5");
    }
  }
  e.preventDefault();
  return false;
}
window.handleDragLeave = function(e) {
  var id = ''
  $(".dragg").each(function(i,el){
    if ($(el).attr("move") == "true") {
      id = $(el).attr('id')
    }
  });
  var ret = [];
  if (id == 'cmyk') {
    for(var i = 0; i < 4; i++) {
      var id = getId($(this).attr('id'), i);
      if ($("#" + id).hasClass('dropzone')){
        ret.push(true)
      } else {
        ret.push(false)
      }
    }
    var unique = ret.filter(function(itm, i, a) {
      return i == ret.indexOf(itm);
    });
    if (!(unique.includes(false))) {
      var c_id = getId($(this).attr('id'), 0);
      var m_id = getId($(this).attr('id'), 1);
      var y_id = getId($(this).attr('id'), 2);
      var k_id = getId($(this).attr('id'), 3);
      $("#" + c_id).css('background-color', "#6c6c6c");
      $("#" + m_id).css('background-color', "#6c6c6c");
      $("#" + y_id).css('background-color', "#6c6c6c");
      $("#" + k_id).css('background-color', "#6c6c6c");
    }
  } else if (id == 'light') {
    for(var i = 0; i < 2; i++) {
      var id = getId($(this).attr('id'), i);
      if ($("#" + id).hasClass('dropzone')){
        ret.push(true)
      } else {
        ret.push(false)
      }
    }
    var unique = ret.filter(function(itm, i, a) {
      return i == ret.indexOf(itm);
    });
    if (!(unique.includes(false))) {
      var lc_id = getId($(this).attr('id'), 0);
      var lm_id = getId($(this).attr('id'), 1);
      $("#" + lc_id).css('background-color', "#6c6c6c");
      $("#" + lm_id).css('background-color', "#6c6c6c");
    }
  } else if (["varnish", "white", "primer", "spot"].includes(id)) {
    if ($(this).hasClass('dropzone')){
      $(this).css('background-color', "#6c6c6c");
    }
  }
  e.preventDefault();
  return false;
}

window.dragDrop = function() {
  $(".dragg").each(function(i,el) {
    el.addEventListener('dragstart', handleDragStart);
    el.addEventListener('dragend', handleDragEnd);
  });
  $(".dropzone").each(function(i,el) {
    el.addEventListener('dragover', handleDragOver);
    el.addEventListener('dragenter', handleDragEnter);
    el.addEventListener('dragleave', handleDragLeave);
    el.addEventListener('drop', handleDrop);
  });
}

window.getId = function(slot_id, n) {
  var id_val = slot_id.split("_")[1];
  var new_id = "slot_" + (Number(id_val) + Number(n))
  return new_id;
}
///////////////////////////////////////////////////////
